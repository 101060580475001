import React,{Component} from 'react'
import {Image} from 'antd'

import pic1 from '../../../static/case/case0/pic1.png'
import pic2 from '../../../static/case/case0/pic2.png'
import pic3 from '../../../static/case/case0/pic3.png'

export default class Index extends Component{

    state = {}

    render(){
        return(
            <div className='easy-in'>
                <div className='f18 b mb30 ac'>智慧消防物联网综合管理应用平台</div>
                <div className='mb30'>
                    <div className='b mb10'>项目背景</div>
                    <div>
                    近年来，随着社会的发展进步，城市高层、大型建筑和各类场所单位日益增多，消防安全形势异常严峻，消防安全监督管理部门人员有限，消防安全监管缺乏有效的技术手段支撑和社会化手段配合，无法及时发现、消除、整改重大火险隐患，火灾风险和发生几率仍然居高不下。     
                    </div>
                    <div>
                    依据《国家综合性消防救援队伍基层建设纲要》、《国家综合性消防救援队伍内务条令》、《国家综合性消防救援队伍队列条令》、《国家综合性消防救援队伍消防员管理规定》等制度要求，结合信息网络、自动控制、人工智能、数据分析等技术，实现消防训练按纲施训、消防队伍要素数字化、设施设备智能化、信息资源网络化和日常管理可视化，有效实现队站营区“可感、可知、可视、可控”。
                    </div>
                    <div className='ac'>
                    <Image width={570} height={'auto'} src={pic1} preview={false} className='mt20' />
                    </div>
                </div>
                <div className='mb30'>
                    <div className='b mb10'>智慧消防整体解决方案</div>
                    <div>
                    采用大数据、物联网等技术，汇聚多方数据，构建消防大数据汇聚与支撑平台，研发贯穿隐患排查、防火监督、灭火救援、后勤管理、大数据创新应用的消防应用系统。实现各项消防业务工作流程化管理和协同运作，落实消防安全责任制，提高各消防责任方的消防科技水平，促进消防工作社会化的贯彻与实施，全面提升消防安全水平。
                    </div>
                    <div>
                    实现城市级消防维保+预警+联控+指挥全管控；解决消防设施维保巡检、隐患预警、远程控制、智能识别、培训演练、监督管理、分析研判、应急指挥全流程业务管理。
                    </div>
                    <div className='mtb20'>
                        <Image width={'100%'} height={'auto'} src={pic2} preview={false} />
                    </div>
                    <div>
                    智慧消防物联网综合管理应用平台是以物联网信息技术为支撑，建成覆盖整个消防系统的网络互联互通、信息资源共享、标准规范统一、应用功能完备的消防信息化技防体系，明显提高消防管理部门信息资源综合开发利用水平，形成全员应用、资源共享的信息化工作格局，实现对区域内消防人员、灭火装备、重要隐患的实时监控、准确定位、轨迹跟踪 。
                    </div>
                    <div className='mtb20 ac'>
                    <Image width={579} height={'auto'} src={pic3} preview={false} />
                    </div>
                </div>
            </div>
        )
    }
}