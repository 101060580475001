import React,{Component} from 'react'
import { Image,Divider  } from 'antd';
import LeftMenu from './leftMenu'
import bannerPic from '../../static/home/banner4.png'
import pic1 from '../../static/about/1.png'
import pic2 from '../../static/about/2.png'
import pic3 from '../../static/about/3.png'
import pic4 from '../../static/about/4.png'
import pic5 from '../../static/about/5.png'
import pic6 from '../../static/about/6.png'
import pic7 from '../../static/about/7.png'
import pic8 from '../../static/about/8.png'
import pic9 from '../../static/about/9.png'
import pic10 from '../../static/about/10.png'
import pic11 from '../../static/about/11.png'
import pic12 from '../../static/about/12.png'


export default class Home extends Component{
    state = {
        arr:[
            {id:1,pic:pic1},
            {id:2,pic:pic2},
            {id:3,pic:pic3},
            {id:4,pic:pic4},
            {id:5,pic:pic5},
            {id:6,pic:pic6},
            {id:7,pic:pic7},
            {id:8,pic:pic8},
            {id:9,pic:pic9},
            {id:10,pic:pic10},
            {id:11,pic:pic11},
            {id:12,pic:pic12},
        ]
    }
    render(){
        const {arr} = this.state;
        return(
            <div className='bg-f7'>
                <div className='relative'>
                     <img src={bannerPic} style={{'width':'100%','height':'300px'}} alt="" />
                    <div className='absolute-center ac text-white no-select' style={{'letterSpacing':'3px'}}>
                        <div className='b f42 mb15'>关于我们</div>
                        <div className='f20' style={{'maxWidth':'600px'}}>
                        专注互联网信息化服务 加强交流合作 共谋协同发展
                        </div>
                    </div>
                </div>
                <div className='main-box'>
                    <div className='f16'>首页 / 关于我们 / 企业荣誉</div>
                    <Divider />
                    <div className='flex'>
                        <LeftMenu></LeftMenu>
                        <div className='flex1 ml20'>
                            <div className='ac b f16'>企业荣誉</div>
                            <div className='mtb20 clear'>
                                <Image.PreviewGroup>
                                {
                                    arr.map(item=>{
                                        return(
                                            <div className='w33 ac' key={item.id}>
                                            <Image width={'200px'} height={'300px'} src={item.pic} />
                                            </div>
                                        )
                                    })
                                }
                                </Image.PreviewGroup>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}