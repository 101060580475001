import React,{Component} from 'react'
import { Divider  } from 'antd';

import Project0 from './Project0'
import Project1 from './Project1'
import Project2 from './Project2'
import Project3 from './Project3'

import bannerPic from '../../static/home/banner3.png'

export default class Home extends Component {
    state = {
        titleIndex:0,
        titleArr:[
            {id:0,title:'NB-烟感、燃气、温感',page:<Project0 />},
            {id:1,title:'LoRa-网关、烟感、燃气、温感',page:<Project1 />},
            {id:2,title:'WiFi系统',page:<Project2 />},
            {id:3,title:'燃气智能切断阀',page:<Project3 />},
        ],
        timer:null,
    }

    chooseTitle = (id)=>{
        const {titleIndex} = this.state;
        if(titleIndex===id){
            return false
        }
        // if(timer){
        //     clearInterval(timer)
        //     this.setState({timer:null})
        // }
        
        // let dxTimer = setInterval(()=>{
        //     let scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
        //     let ispeed = Math.floor(-scrollTop/6);
        //     console.log(ispeed)
        //     if(scrollTop===0){
        //         clearInterval(dxTimer);
        //         this.setState({timer:null})
        //     }
        //     document.documentElement.scrollTop=document.body.scrollTop=scrollTop+ispeed;
        // },20)

        // this.setState({timer:dxTimer})

        this.setState({titleIndex:id})
    }

    render(){
        const {titleArr,titleIndex} = this.state;
        return (
            <div>
                <div className='relative'>
                    <img src={bannerPic} style={{'width':'100%','height':'300px'}} alt="" />
                    <div className='absolute-center ac text-white no-select'style={{'letterSpacing':'3px'}}>
                        <div className='b f42 mb15'>产品中心</div>
                        <div className='f20' style={{'maxWidth':'600px'}}>
                        专业 可靠 用心打造精品 推动智慧城市发展
                        </div>
                    </div>
                </div>
                <div className='bg-f7'>
                    <div className='main-box'>
                        <div className='f16'>首页 / 产品 / {titleArr[titleIndex].title||'?'}</div>
                        <Divider />
                        <div className='flex'>
                            <div className='left-title-box'>
                                {
                                    titleArr.map(item=>{
                                        return (
                                            <div key={item.id} onClick={()=>this.chooseTitle(item.id)}
                                            className={`title-item ${item.id===titleIndex?'title-item-active':''}`}>{item.title}</div>
                                        )
                                    })
                                }
                            </div>
                            <div className='flex1 ml50'>
                                {
                                    titleArr[titleIndex].page
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}