import React from 'react'
import { useNavigate } from "react-router-dom";
import {Image} from 'antd'
import eventBus from '../../libs/event.js'
import rightIcon from '../../static/home/rightIcon.png'

export default function CardItem(props){
    const push = useNavigate();

    const toDetailPage = ()=>{
        push(`/case`,{
            state:{
               index :props.id
            }
        });
        eventBus.emit("changeMenuActive",'#/case')   
    }

    return(
        <div className='w33 plr15 pointer'>
            <div className=' shadow-gray'  onClick={toDetailPage}>
                <Image width={'100%'} height={260} src={props.pic} preview={false}></Image>
                <div className='p10'>
                    <div className='b mb10 of'>{props.title}</div>
                    <div className='of of3'>{props.content}</div>
                    <div className='ar'>
                        <Image src={rightIcon} preview={false} width={20} height={16}></Image>
                    </div>
                </div>
            </div>
        </div>
    )
}