import React,{Component} from 'react'
import {Image} from 'antd'

import one from '../../../static/product/device1/one.jpg'
import device1 from '../../../static/product/device1/device1.png'
import device2 from '../../../static/product/device1/device2.png'
import device3 from '../../../static/product/device1/device3.png'


export default class Index extends Component{

    state = {}

    render(){
        return(
            <div className='easy-in'>
                <div className='f18 b mb30 ac'>NB-烟感、燃气、温感</div>
                <div className='mb30'>
                    <span className='b'>NB-IoT</span>
                    （Narrow Band Internet of Things），即基于蜂窝的窄带物联网，作为远距离无线通讯技术中的佼佼者，NB-IoT具备高安全、广覆盖、低功耗、大连接和低成本这五大亮点。
                </div>
                <div className='mb30'>
                    <div className='b mb10'>NB-IoT智慧消防整体解决方案</div>
                    <div>
                        基于物联网、大数据存储和分析、云计算、移动互联网，结合火灾探测报警行业特征，提供系统化综合报警方案。使用物联网技术的智慧烟雾报警器、燃气报警器和感温火灾报警器，可以第一时间感知火灾信息、监测环境中目标气体泄漏或液体挥发，分析气体浓度达到或超过报警限值时、探测温度，当温度超过限量时并即时广域传输给业主、物业、安监、消防等部门，起到防范于未然等效果，有效保证生命财产安全。
                    </div>
                    <div className='mtb20'>
                        <div className='flex flex-center fc'>
                            <div className='flex1 ac'>
                                <div className='relative dx'>
                                    <img src={device1} className='auto-img' alt="" />
                                    <div className='hover-content'>
                                        <div>
                                            <span className='mr20'>工作电压</span>DC 3V
                                        </div>
                                        <div>
                                            <span className='mr20'>报警响度</span>{'>= 85dB/3M'}
                                        </div>
                                        <div>
                                            <span className='mr20'>电池寿命</span>10年
                                        </div>
                                        <div className=''>
                                            <span className='mr20'>通讯方式</span>NB-IOT
                                        </div>
                                    </div>
                                </div>
                                <div className='mtb10 of'>KD-122NB烟雾报警器</div>
                            </div>
                            <div className='flex1 ac mlr5'>
                                <div className='relative dx'>
                                    <img src={device2} className='auto-img' alt="" />
                                    <div className='hover-content'>
                                        <div>
                                            <span className='mr20'>探测气体</span>烷类气体
                                        </div>
                                        <div>
                                            <span className='mr20'>工作电压</span>AC 200V
                                        </div>
                                        <div>
                                            <span className='mr20'>额定功率</span>5W
                                        </div>
                                        <div>
                                            <span className='mr20'>报警响度</span>{'> 70dB(1M距离)'}
                                        </div>
                                        <div>
                                            <span className='mr20'>整机寿命</span>5年
                                        </div>    
                                        <div>
                                            <span className='mr20'>通讯方式</span>NB-IOT
                                        </div>
                                    </div>    
                                </div>   
                                <div className='mtb10 of'>KD-212LB 燃气报警器</div>
                            </div>
                            <div className='flex1 ac'>
                                <div className='relative dx'>
                                    <img src={device3} className='auto-img' alt="" />
                                    <div className='hover-content'>
                                        <div>
                                            <span className='mr20'>工作电压</span>DC 3V
                                        </div>
                                        <div>
                                            <span className='mr20'>低电检测</span> {'<= 2.2V'}
                                        </div>
                                        <div>
                                            <span className='mr20'>报警响度</span>{'>= 75dB/3M'}
                                        </div>
                                        <div className=''>
                                            <span className='mr20'>通讯方式</span>NB-IOT
                                        </div>
                                    </div>
                                </div>
                                <div className='mtb10 of'>KD-705LB 感温火灾报警器</div>
                            </div>
                        </div>
                        <div className='ac'>
                        <Image width={'100%'} preview={false}  height={'auto'} src={one} />
                        </div>
                    </div>
                    <div>
                    智慧消防云平台是一个采用“感、传、知、用”等物联网技术手段，通过互联网，对消防设施、器材、人员等状态进行智能化感知、识别、定位与跟踪，实现实时信息传递，分析，为防火监督管理和灭火救援提供信息支撑，提高社会化消防监督与管理水平的智能化平台。能够对烟、水、电、气等引发的消防事故因素进行24小时监测控制。
                    </div>
                </div>
                <div className='mb30'>
                    <div className='b mb10'>NB-IoT在智慧烟感中的特性</div>
                    <div>
                        <span className='b'>高可靠：</span>
                        网络部署减少中间通信环节，直接一跳上网，降低故障概率，提高传输可靠性，无需设备厂家具备无线网规能力，免网络维护。</div>
                    <div>
                        <span className='b'>高安全：</span>
                        运营商网络基于授权频谱组网，电信级高可靠、抗干扰，基于3GPP标准网络层安全+传输层安全方案+应用层安全，三层保障物联网终端及数据安全。</div>
                    <div>
                        <span className='b'>深覆盖：</span>
                        能实现比GSM高20db的覆盖增益；覆盖能力提升了100倍，即使在地下车库、地下室、地下管道等信号难以到达的地方也能覆盖到。</div>
                    <div>
                        <span className='b'>低功耗：</span>
                        电池待机时长可达5年以上，无需频繁更换电池，降低维护成本。</div>
                    <div>
                        <span className='b'>可演进：</span>
                        以NB-IoT为代表的LPWA（低功耗广域）物联网技术，支持面向5GmMTC（海量机器类通信）应用方向，持续演进。</div>
                    <div>
                        <span className='b'>可感知：</span> 
                        可远程独立监控每一个感烟探测器，周期性上报烟感器状态，可远程配置与诊断探测器及通信问题。</div>
                    <div>
                        <span className='b'>易安装：</span> 
                        无需布线和取电，即插即用，安装和上网调试简单，部署成本降低90%以上。</div>
                </div>
            </div>
        )
    }
}