import React,{Component} from 'react'
import {Image} from 'antd'

import pic1 from '../../../static/case/case2/pic1.png'
import pic2 from '../../../static/case/case2/pic2.png'

export default class Index extends Component{

    state = {}

    render(){
        return(
            <div className='easy-in'>
                <div className='f18 b mb30 ac'>湖南电气火灾管理应用平台</div>
                <div className='mb30'>
                    <div className='b mb10'>项目背景</div>
                    <div>
                    随着电气化设备在高科技智能化状态不断进步，各种建筑物内部的电气设备及电气线路越来越复杂，因供电线路或用电设备绝缘损坏引发的电气火灾的现象越来越多。据统计，我国火灾80%为建筑火灾，而电气则是引发建筑火灾的首要因素，电气火灾大部分是短路、泄漏电流、断路器或线路超负荷等原因引起的。因此，对配电线路漏电引起的电气火灾隐患进行及时有效的监控是防止电气火灾频发的必要措施。
                    </div>
                </div>
                <div className='mb30'>
                    <div className='b mb10'>智能电气火灾管理解决方案</div>
                    <div>
                    基于移动互联网的电气安全云平台，通过先进的智能传感器，全天候将电气线路安全数据实时传送至云平台，实现电气安全隐患管理，并实时监控、远程控制、web端管理、APP应用、大数据分析及社区和企业级电气安全服务。通过物联网技术对电气引发火灾的主要因素（导线温度、电流、电压和漏电流）进行不间断的数据跟踪与统计分析，实时发现电气线路和用电设备存在的安全隐患（如：线缆温度异常、短路、过载、过压、欠压及漏电等），第一时间发出警报信息并准确报出故障点，自动通知相关人员及时排除电气火灾隐患。
                    </div>
                    <div className='mtb20'>
                        <Image width={'100%'}  height={'auto'} preview={false} src={pic1} />
                    </div>
                    <div>
                    另外系统选配故障电弧、烟感报警器和可燃气体报警器等监测模块，通过NB-IOT、LoRa、WiFi等网络接入，系统可接入并实时报警。
                    </div>
                    <div>
                    通过数据网关采集包括安全状态在线监测、故障诊断、趋势分析三大部分组成。具体功能包括实时监控、故障报警、远程管理、历史查询、消防联动、故障分析、趋势预测、短信报警等；
                    </div>
                    <div className='mtb20'>
                    <Image width={'100%'}  height={'auto'} preview={false} src={pic2} />
                    </div>
                    <div className='mtb20'>
                        <div className='b mb10'>湖南智能电气火灾管理系统的优势</div>
                        <div>
                        实时采集、远程监控：实时显示当前所监控的每一路漏电流、电流、烟雾、温度，实时自动切换一路显示，可通过主动查询每一路信息，报警发生时，则直接跳转到报警显示。
                        </div>
                        <div>
                        实时报警、准确高效：显示当前正在发生的报警信息。可通过查询其正在报警的内容；可以直接对报警进行消音；报警复位，若报警确实解除，则返回监控模块，若报警还存在，则重新进入报警模块。
                        </div>
                        <div>
                        事先排查、提前预防：可精确获取漏电流数据，并提前预警；可查询历史数据和实际数据对比，提前预警；精确半段线路是否温度过度升高，提前预警；精确定位漏电点，协助人员进行排查。
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}