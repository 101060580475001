import React,{Component} from 'react'
import {Image} from 'antd'

import pic1 from '../../../static/solution/solution0/pic1.png'
import pic2 from '../../../static/solution/solution0/pic2.png'

export default class Index extends Component{

    state = { }

    render(){
        return(
            <div className='easy-in'>
                <div className='f18 b mb30 ac'>LORA智慧城市安防解决方案</div>
                <div className='mb30'>
                    <div className='b mb10'>方案背景</div>
                    <div>
                    随着经济的发展和城市建设速度的加快，全国各地区的安全问题接踵而至，我国正进入“突发公共事件的高发期”和“社会高风险期”，每年因各种灾害、事故造成的经济损失达6500亿元左右，占GDP的6%。应对重大突发公共安全事件的处置能力是城市现代化程度的一个重要标志，公共安全问题已经延伸到生产、生活、环境、技术、信息等社会各个领域。特别是近几年，随着信息技术的发展，构建和谐社会、建设平安城市的需要以及大型赛事、展会活动的安防需求，对公共安全有效管理的需求越来越旺盛，标准越来越高。
                    </div>
                </div>
                <div className='mb30'>
                    <div className='b mb10'>方案概述</div>
                    <div>
                    智慧城市安防建设强调对城市的更智能感知，智慧安防以视频监控系统为基础，可以帮助城市管理者实现可视化的感知。同时，通过各种有线、无线网络，整合城市各类视频数据，建设一个庞大的城市公共安全防控平台，利用云计算技术，对海量的城市视频进行存储与分析，实现事前积极预防、事中实时感知和快速响应以及事后的快速调查分析。
                    </div>
                    <div>
                    智慧城市安防目标是构建城市综合预警系统和应急指挥体系。建设一套城市治安综合治理、城市交通管理和消防调度指挥于一体的城市综合管理系统，以科技创新带动城市安防工作创新，充分发挥政府和其它各行政部门、城管、社区联防的力量，将遍布城市各种角落的信息资源整合在一起，通过统一管理和分析使其发挥作用，帮助政府管理者实时了解城市各方面情况，及时响应和处理。
                    </div>
                    <div className='mt20 ac'>
                        <Image width={'auto'}  height={'auto'} src={pic1} preview={false} />
                    </div>
                </div>
                <div className='mb30'>
                    <div className='b mb10'>LoRa技术解决方案</div>
                    <div>
                    LoRa技术独立于蜂窝网络。是一种低功耗远程无线通信技术，它的覆盖范围广，算是一种广域网WAN，LoRa采用扩频技术。远程(LoRa)无线技术可以帮助城市克服网络覆盖方面的难题，并使他们能够在智能城市中广泛部署物联网解决方案，从而提供传统Wi-Fi解决方案无法提供的覆盖范围。
                    </div>
                    <div>
                    智慧城市安防平台基于LoRa物联网技术，由烟雾警报器等设备、基站设备、网络服务平台、云服务器、客户端等组成。系统通过采集的信息同步发送到物联网云平台，由云平台推送通知相关部门人员实时处理情况。
                    </div>
                    <div className='mt20 ac'>
                         <Image width={'auto'}  height={'auto'} src={pic2} preview={false} />
                    </div>
                </div>
                <div className='mb30'>
                    <div className='b mb10'>方案优势</div>
                    <div>
                        <span className='b'>易于组网：</span>
                        只需将网关安装在稿件组顶端，即可实现大范围网络覆盖，无需复杂布线、安装。</div>
                    <div>
                    <span className='b'>低能耗：</span>
                        由于设备大部分时间处于睡眠状态，只有特定情况才会被唤醒，所以大大的延长了电池的使用寿命，使用时间甚至可以达到几年。</div>
                    <div>
                    <span className='b'>成本低：</span>
                        安装简单，硬件成本低，应用范围广，多种终端可以共用一个网络。</div>
                    <div>
                        <span className='b'>传输距离远：</span>
                        网关具备超长的传输距离，且穿透性较强、超强抗干扰能力。</div>
                    <div>
                        <span className='b'>频段免授权：</span>
                        网络工作频段470M，发射功率小于50毫瓦，无需无线电管理委员会审批即可大规模组网。</div>
                </div>
            </div>
        )
    }
}