import React,{Component} from 'react'
import {Image} from 'antd'

import pic1 from '../../../static/solution/solution0/pic1.png'
import pic2 from '../../../static/solution/solution1/pic2.png'

export default class Index extends Component{

    state = {  }

    render(){
        return(
            <div className='easy-in'>
                <div className='f18 b mb30 ac'>NB-IOT智慧城市安防解决方案</div>
                <div className='mb30'>
                    <div className='b mb10'>方案背景</div>
                    <div>
                    随着经济的发展和城市建设速度的加快，全国各地区的安全问题接踵而至，我国正进入“突发公共事件的高发期”和“社会高风险期”，每年因各种灾害、事故造成的经济损失达6500亿元左右，占GDP的6%。应对重大突发公共安全事件的处置能力是城市现代化程度的一个重要标志，公共安全问题已经延伸到生产、生活、环境、技术、信息等社会各个领域。特别是近几年，随着信息技术的发展，构建和谐社会、建设平安城市的需要以及大型赛事、展会活动的安防需求，对公共安全有效管理的需求越来越旺盛，标准越来越高。
                    </div>
                </div>
                <div className='mb30'>
                    <div className='b mb10'>方案概述</div>
                    <div>
                    智慧城市安防建设强调对城市的更智能感知，智慧安防以视频监控系统为基础，可以帮助城市管理者实现可视化的感知。同时，通过各种有线、无线网络，整合城市各类视频数据，建设一个庞大的城市公共安全防控平台，利用云计算技术，对海量的城市视频进行存储与分析，实现事前积极预防、事中实时感知和快速响应以及事后的快速调查分析。
                    </div>
                    <div>
                    智慧城市安防目标是构建城市综合预警系统和应急指挥体系。建设一套城市治安综合治理、城市交通管理和消防调度指挥于一体的城市综合管理系统，以科技创新带动城市安防工作创新，充分发挥政府和其它各行政部门、城管、社区联防的力量，将遍布城市各种角落的信息资源整合在一起，通过统一管理和分析使其发挥作用，帮助政府管理者实时了解城市各方面情况，及时响应和处理。
                    </div>
                    <div className='mt20 ac'>
                        <Image width={'auto'}  height={'auto'} src={pic1} preview={false} />
                    </div>
                </div>
                <div className='mb30'>
                    <div className='b mb10'>NB-IOT技术解决方案</div>
                    <div>
                    基于蜂窝的窄带物联网（NB-IoT）成为万物互联网络的一个重要分支，支持低功耗设备在广域网的蜂窝数据连接，也被叫作低功耗广域网(LPWAN)。NB-IoT支持待机时间长、对网络连接要求较高设备的高效连接。据说NB-IoT设备电池寿命可以提高至少10年，同时还能提供非常全面的室内蜂窝数据连接覆盖。
                    </div>
                    <div>
                    智慧城市安防平台基于NB-IoT物联网技术，由烟雾警报器等设备、基站设备、网络服务平台、云服务器、客户端等组成。系统通过采集的信息同步发送到物联网云平台，由云平台推送通知相关部门人员实时处理情况。
                    </div>
                    <div className='mt20 ac'>
                         <Image width={'auto'}  height={'auto'} src={pic2} preview={false} />
                    </div>
                </div>
                <div className='mb30'>
                    <div className='b mb10'>方案优势</div>
                    <div>
                        <span className='b'>海量连接：</span>
                        在同一基站的情况下，NB-IoT可以比现有无线技术提供50~100倍的接入数。</div>
                    <div>
                        <span className='b'>深度覆盖：</span>
                        NB-IoT比LTE提升20dB增益，相当于发射功率提升了100倍，即覆盖能力提升了100倍。</div>
                    <div>
                        <span className='b'>成本低：</span>
                        低速率低功耗低带宽带来的是低成本优势。NB-IoT芯片可以做得很小，尺寸越小，成本越低，模块的成本也随之变低。</div>
                    <div>
                        
                        NB-IoT聚焦小数据量、小速率应用，因此NB-IoT设备功耗可以做到非常小。</div>
                    <div>
                        <span className='b'>稳定可靠：</span>
                        NB-IoT直接部署于GSM网络、UMTS网络或LTE网络，不占用现有网络的语音和数据带宽，保证传统业务和未来物联网业务可同时稳定、可靠的进行。</div>
                </div>
            </div>
        )
    }
}