import React,{useState,useEffect } from 'react'
import { Divider  } from 'antd';
import { useLocation } from 'react-router-dom';

import Project0 from './Project0'
import Project1 from './Project1'
import Project2 from './Project2'
import Project3 from './Project3'
import Project4 from './Project4'
import Project5 from './Project5'

import bannerPic from '../../static/home/banner1.png'

export default function Home(){
    let location = useLocation();

    const [titleIndex, setIitleIndex] = useState(0);//析构赋值
    const [titleArr] = useState(
        [
            {id:0,title:'智慧消防物联网综合管理应用平台',page:<Project0 />},
            {id:1,title:'江苏柒熙燃气安全智能管理系统',page:<Project1 />},
            {id:2,title:'湖南电气火灾管理应用平台',page:<Project2 />},
            {id:3,title:'智能养老社区平台',page:<Project3 />},
            {id:4,title:'智能模具工厂管理平台',page:<Project4 />},
            {id:5,title:'铠盾OA自动化办公系统',page:<Project5 />},
        ]
    );

    const chooseTitle = (id)=>{
        if(titleIndex===id){
            return false
        }
        setIitleIndex(id)
    }

    useEffect(()=>{
        if(location.state){
            const { index } = location.state;
            chooseTitle(index-1 || 0) ;
        }
        window.scrollTo(0, 0);
    } , [] )

    return(
        <div>
            <div className='relative'>
                <img src={bannerPic} style={{'width':'100%','height':'300px'}} alt="" />
                <div className='absolute-center ac text-white no-select'style={{'letterSpacing':'3px'}}>
                    <div className='b f42 mb15'>案例展示</div>
                    <div className='f20' style={{'maxWidth':'600px'}}>
                    品质之作 信任之选 合作共赢
                    </div>
                </div>
            </div>
            <div className='bg-f7'>
                <div className='main-box'>
                    <div className='f16'>首页 / 案例展示 / {titleArr[titleIndex].title||'?'}</div>
                    <Divider />
                    <div className='flex'>
                        <div className='left-title-box'>
                            {
                                titleArr.map(item=>{
                                    return (
                                        <div key={item.id} onClick={()=>chooseTitle(item.id)}
                                        className={`title-item ${item.id===titleIndex?'title-item-active':''}`}>{item.title}</div>
                                    )
                                })
                            }
                        </div>
                        <div className='flex1 ml50'>
                            {
                                titleArr[titleIndex].page
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}