import React,{Component} from 'react'
import {Image} from 'antd'

import pic1 from '../../../static/case/case3/1.png'
import pic2 from '../../../static/case/case3/2.png'
import pic3 from '../../../static/case/case3/3.png'

export default class Index extends Component{

    state = {}

    render(){
        return(
            <div className='easy-in'>
                <div className='f18 b mb30 ac'>智能养老社区平台</div>
                <div className='mb30'>
                    <div className='b mb10'>项目背景</div>
                    <div>
                    我国确立了“以居家为基础、社区为依托、机构为支撑”的养老产业框架和9073养老格局，
90%在家中养老（居家养老）；国家卫健委2022年发布，截至2021年底，我国60岁及以上
老年人口有2.67亿人，占总人口18.9%，其中，65岁及以上人口达到2亿人，占14.2%。十
四五末期，中国老龄人口将超过3亿；2035年左右我国60岁及以上老年人口将突破4亿，在
2050年前后达到顶峰，约占总人口的1/3。那时，中国将有4.8亿、甚至接近5亿老年人，是
世界上老年人口最多的国家。养老服务业目前已超10万亿市场规模。
                    </div>
                </div>
                <div className='mb30'>
                    <div className='b mb10'>智能养老社区平台管理解决方案</div>
                    <div>
                    智能社区养老平台是承接居家养老和机构养老之间的重要纽带，它既能满足居家老人在居家场
景下的即时性服务保障问题，又能将养老机构的标准化服务和先进理念延伸到社区，最大满足
居家老人的用户体验。
智能社区养老平台是充分利用互联网、物联网、移动互联网、云计算、大数据等先进技术和理
念，建立一套成熟完善的智能化健康养老信息管理体系，为社区老年居民提供一个实时、智
能、快捷、高效的生活环境。智能社区养老主要分为老人档案管理、一键报警、预警事件工
单、呼叫中心、居家安全、远程设备监控等功能。
                    </div>
                    <div className='mtb20'>
                        <Image width={'100%'}  height={'auto'} preview={false} src={pic1} />
                    </div>
                    <div>
                    智慧养老社区管理平台，视觉展示更加一目了然、简明清晰，给你更流畅的浏览体验。功能操
作更简单、方便、快捷，有效的提高工作效率。
智慧养老社区管理平台，具有基础老人信息管理、设备报警管理、智能应急监控中心、手机移动
端管理、一键报警、预警事件管理、呼叫管理、远程设备监控等功能，满足服务人员日常管理，
保证物联设备正常运行，打造⼀个智慧、安全、高效的智慧养老社区。
                    </div>
                    <div className='mtb20'>
                    <Image width={'100%'}  height={'auto'} preview={false} src={pic2} />
                    </div>
                    <div>
                    APP软件为社区老人和家属提供老人信息查询、设备查询、管理、健康安全、预警查询指导等。
管理人员可以通过手机端直接管理老人档案信息、预警处理、警情处理、设备管理等功能。
                    </div>
                    <div>
                        <Image width={'100%'}  height={'auto'} preview={false} src={pic3} />
                    </div>
                    <div className='mtb20'>
                        <div className='b mb10'>智能养老社区平台管理系统的优势</div>
                        <div>
                        智能社区养老是充分利用互联网、物联网、移动互联网、云计算、大数据等先进技术和理念，建
立一套成熟完善的智能化健康养老信息管理体系，通过模式、技术输出，为政府、老人、子女、
养老服务提供商、社区管理中心搭建四方养老服务平台，形成的一整套有效可持续智慧居家养老
解决方案，让老人、子女满意，子女无后顾之忧，安心地工作；老人老有所依，老有所乐。
                        </div>
                        <div>
                        <span className='b'>健康管理</span>：平台可以通过与各类健康设备的连接，实时监测老年人的健康状况，如心率、血压、血糖
等，及时发现异常情况并提醒老年人或其亲属。同时，平台还可以提供健康咨询、健康档案管理等服
务，帮助老年人更好地管理自己的健康。
                        </div>
                        <div>
                        <span className='b'>生活服务</span>：平台可以提供各类生活服务，如在线购物、餐饮配送、家政服务等，让老年人在家中就能
享受到便捷的生活。此外，平台还可以根据老年人的需求和偏好，推荐合适的娱乐、文化、旅游等活
动，丰富老年人的生活。
                        </div>
                        <div>
                        <span className='b'>安全保障</span>：平台可以通过安装智能监控设备、智能家居设备等，提高老年人的居家安全。同时，平台
还可以提供紧急救援服务，如一键呼叫、自动定位等，确保老年人在遇到紧急情况时能够得到及时的
救助。
                        </div>
                        <div>
                        <span className='b'>社交互动</span>：平台可以提供社交功能，让老年人能够与其他老年人、家人、朋友等进行互动和交流。这
有助于缓解老年人的孤独感，提高他们的生活质量。
                        </div>
                        <div>
                        <span className='b'>数据分析与优化</span>：平台通过收集和分析老年人的行为数据、健康数据等，可以为老年人提供更加个性
化的服务。同时，这些数据还可以为社区管理者提供决策支持，帮助他们优化社区管理和服务。
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}