import React,{Component} from 'react'
import { Link  } from 'react-router-dom'
import { Menu ,Image } from 'antd';
import logoPic from '../../static/base/logo.png'
import './index.css'

import eventBus from '../../libs/event.js'

const { SubMenu } = Menu;
class Header extends Component{
    state = {
        current:'#/home',
    }

    componentDidMount(){
        eventBus.addListener('changeMenuActive', this.changeMenuActive);

        let hash = window.location.hash;
        // hash = (hash === '/' ? '/home':hash);
        this.setState({current:hash})

        // 监听浏览器点击返回
        if (window.history && window.history.pushState) {
            window.addEventListener('popstate', this.handleBack, false);
        }
    }
    componentWillUnmount () {
        eventBus.removeListener('changeMenuActive', this.changeMenuActive);

        window.removeEventListener('popstate', this.handleBack, false);
    };

    handleBack = ()=>{
        let hash = window.location.hash;
        this.setState({current:hash})
    }

    menuClick = (e)=>{
        this.setState({current:e.key})
    }

    changeMenuActive = (e)=>{
        this.setState({current:e})
    }

    render(){
        const { current } = this.state;
        return (
            <div className='flex flex-center'>
                <div className='b f16 text-white flex1'>
                    <Image src={logoPic} preview={false} width={207} height={30}></Image>
                </div>
                <div className='dx'>
                    <Menu onClick={this.menuClick} selectedKeys={[current]} mode="horizontal"
                    style={{'background':'#187aff','color':'#fff'}}>
                        <Menu.Item key="#/home">
                            <Link to="/home">首页</Link>
                        </Menu.Item>
                        <Menu.Item key="#/product">
                            <Link to="/product">产品</Link>
                        </Menu.Item>
                        <Menu.Item key="#/case">
                            <Link to="/case">案例展示</Link>
                        </Menu.Item>
                        <Menu.Item key="#/solution">
                            <Link to="/solution">解决方案</Link>
                        </Menu.Item>
                        <SubMenu key="#/aboutUs" title="关于我们">
                            <Menu.Item key="#/introduce">
                                <Link to="/introduce">公司介绍</Link>
                            </Menu.Item>
                            <Menu.Item key="#/honor">
                                <Link to="/honor">企业荣誉</Link>
                            </Menu.Item>
                            <Menu.Item key="#/concat">
                                <Link to="/concat">联系我们</Link>
                            </Menu.Item>
                            <Menu.Item key="#/convention">
                                <Link to="/convention">自律公约</Link>
                            </Menu.Item>
                        </SubMenu>
                    </Menu>  
                </div>
            </div>
        )
    }
}
export default Header