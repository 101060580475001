import React,{Component} from "react";

import h5_pic from '../../static/base/h5.png'
export default class H5Pad extends Component{
    state={
        isShowPic:false,
    }

    componentDidMount(){
        // 一加手机不建议用默认浏览器打开
        this.test()
    }

    getPadSize = ()=> {
        let url = window.location.hash; //获取url中"?"符后的字串 
        let arr = url.split('=')||[];
        return arr[1]
    }

    test = async()=>{
        this.setState({isShowPic:false})
        let size = this.getPadSize()
        let postData = {
            appType:'android',
            appName:'家之安-pad-'+size,
        }
        let res = await this.ajax(postData);
        alert(res)
        window.location.href = res;
    }
   
    ajax = async (postData) =>{
        const url = 'https://weixin.jzasafe.com/miniapp/app/getVersion'
        // let postData = {
        //     appType:'android',
        //     appName:'家之安-pad'
        // }

        const options = {
            method:'post',
            headers: {
                'Content-Type': 'application/json;charset=utf-8;'
            },
            body:JSON.stringify(postData)
        }
        try {
            let response = await fetch(url,options);
            let data = await response.json();
            if(data.resp_code === 1){
                let apkUrl = data.datas.url;
                return apkUrl
            }
        } catch(e) {
            console.log("Oops, error", e);
        }
    }

    render(){
        const {isShowPic} = this.state;
        return (
            <div className="bg-white">
                {
                    isShowPic?<img src={h5_pic} style={{'width':'100vw','height':'100vh'}}
                    alt="" />:''
                }
                
            </div>
        )
    }
}