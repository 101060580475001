import React,{Component} from 'react'
import {Image} from 'antd'

import pic1 from '../../../static/case/case5/1.png'
import pic2 from '../../../static/case/case5/2.png'
import pic3 from '../../../static/case/case5/3.png'

export default class Index extends Component{

    state = {}

    render(){
        return(
            <div className='easy-in'>
                <div className='f18 b mb30 ac'>铠盾OA自动化办公系统</div>
                <div className='mb30'>
                    <div className='b mb10'>项目背景</div>
                    <div>
                    铠盾OA自动化办公系统项目的背景主要源于现代企业对高效、便捷、智能化的办公
环境的迫切需求。随着信息技术的快速发展，传统的手工办公方式已经无法满足企业
日益增长的业务需求，而自动化办公系统则成为提升企业运营效率、降低成本、加强
信息管理的有效手段。
                    </div>
                </div>
                <div className='mb30'>
                    <div className='b mb10'>铠盾OA自动化办公系统解决方案</div>
                    <div>
                    铠盾OA自动化办公系统解决方案是一个全面、高效且灵活的自动化办公方案，旨在帮
助企业实现办公流程的数字化、信息化和智能化。该方案通过集成先进的信息技术和
自动化工具，优化企业内部管理流程，提高工作效率，降低运营成本，从而为企业创
造更大的价值。
                    </div>
                    <div className='mtb20'>
                        <Image width={'100%'}  height={'auto'} preview={false} src={pic1} />
                    </div>
                    <div>
                    铠盾OA自动化办公系统WEB端是一个基于Web技术的在线办公平台，为企业员工提
供了便捷、高效的办公体验。通过WEB端，员工可以随时随地访问办公系统，进行文
档处理、任务管理、流程审批等操作。
                    </div>
                    <div className='mtb20'>
                    <Image width={'100%'}  height={'auto'} preview={false} src={pic2} />
                    </div>
                    <div>
                    APP移动端应用充分利用了现代移动设备的特性，将办公流程、任务管理、文档处理等
功能集成于手机或平板等便携设备上，使员工能够随时随地处理工作事务，实现真正的
移动办公。
                    </div>
                    <div>
                        <Image width={'100%'}  height={'auto'} preview={false} src={pic3} />
                    </div>
                    <div className='mtb20'>
                        <div className='b mb10'>铠盾OA自动化办公系统的优势</div>
                        <div>
                        铠盾OA自动化办公系统是一款高效、便捷、智能化的办公自动化解决方案，旨在帮助企
业实现办公流程的自动化、信息化和数字化。该系统通过集成先进的IT技术，优化办公流
程，提升工作效率，为企业创造更大的价值。
                        </div>
                        <div>
                        <span className='b'>提高工作效率</span>：铠盾OA自动化办公系统通过自动化处理办公任务，极大地减少了人工操作，
提高了工作效率。员工可以通过系统快速处理文档、任务、审批等日常办公事务，无需耗费
大量时间在繁琐的手工操作中。同时，系统支持多人同时在线协作，促进了团队之间的信息
共享和高效协同。
                        </div>
                        <div>
                        <span className='b'>优化业务流程</span>：铠盾OA自动化办公系统通过流程引擎实现了业务流程的自动化处理。企业
可以根据自身需求自定义审批、报销、请假等业务流程，并通过系统自动化执行。这大大简
化了业务处理流程，降低了人为错误和延误的可能性，提高了业务处理的准确性和效率。
强化信息安全：信息安全是企业办公自动化的重要保障。铠盾OA自动化办公系统采用先进
的信息加密和权限控制技术，确保企业信息的安全性。系统可以对不同用户设置不同的访问
权限，防止敏感信息泄露。同时，系统还提供数据备份和恢复功能，确保企业数据的完整性
和可靠性。
                        </div>
                        <div>
                        <span className='b'>灵活定制与扩展</span>：铠盾OA自动化办公系统支持根据企业的实际需求进行灵活定制。企业可
以根据自身的业务流程、组织结构和人员配置等因素，调整和优化系统的功能模块和流程设计。
此外，系统还具备良好的扩展性，可以随着企业的发展和需求变化进行升级和扩展。
易用性与用户体验：铠盾OA自动化办公系统注重用户体验和易用性。系统采用直观友好的
界面设计，使得员工能够轻松上手并快速熟悉操作。同时，系统还提供丰富的在线帮助和
教程资源，方便员工随时学习和解决问题。这种良好的用户体验有助于提升员工的工作满
意度和积极性。
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}