import React,{Component} from 'react'
import {Image} from 'antd'

import pic1 from '../../../static/case/case1/pic1.png'
import pic2 from '../../../static/case/case1/pic2.png'

export default class Index extends Component{

    state = { }

    render(){
        return(
            <div className='easy-in'>
                <div className='f18 b mb30 ac'>江苏柒熙燃气安全智能管理系统</div>
                <div className='mb30'>
                    <div className='b mb10'>项目背景</div>
                    <div>
                    随着城市的快速发展，燃气管理日益庞杂，传统的管理手段已不能满足燃气管理的需要。燃气管理的建设、管理、巡检、维修、应急均与城市地理空间位置紧密相关。江苏柒熙燃气安全智能管理系统是基于燃气管理信息系统，利用物联网、移动智能终端等技术手段，以提升管理效率和服务水平，为燃气企业带来了良好的经济效益和社会效益。
                    </div>
                </div>
                <div className='mb30'>
                    <div className='b mb10'>智慧燃气整体解决方案</div>
                    <div>
                    由于燃气的使用、维护涉及多个不同的角色，包括普通的用户，政府监管部门，燃气公司，维保公司等等。通过该系统汇总各方的数据，形成完整的闭合工作流，将燃气的使用从申请安装到后期维保、报修进行管理，同时推动各项任务系统的有序执行。
                    </div>
                    <div>
                    借助新的科技设备帮助各类角色优化流程、快速获取信息、把握重点，采用电话、短信、微信通知的方式进行交互，将燃气与日常的通讯相结合，解决消息送达迟滞的问题。
                    </div>
                    <div>
                    此系统将各个角色的日常任务流程化，自动发起、执行、动态验收，简化日常工作。同时将信息及时反馈，高效简洁的完成日常工作任务，根据需要生成各类报表，同时生成各类的统计分析数据，为企业、部门的日常决策，提供数据支持。
                    </div>
                    <div className='mtb20 ac'>
                        <Image width={'100%'}  height={'auto'} src={pic1} preview={false} />
                    </div>
                    <div>
                    通过数据网关采集气体检测设备的实时系统数据，上传平台实现远程控制、智能呼叫、报警定位、实时防护、视频监控、24小时智能监测、报表统计等功能，彻底实现无接触工作模式，全方位保障用气安全。
                    </div>
                    <div>
                    智能设备通过无线通信技术（GPRS、NB-IOT、LORA、ZigBee等）将设备的实时状态（包括是否泄漏，可燃、有毒、有害气体实时浓度监测，报警器设备生命周期的管理等）主动上报监控平台，并可根据平台控制指令进行远程控制。
                    </div>
                    <div>
                    云平台接收并处理设备上报数据、通过数据分析后若设备处于异常状态可通过页面、电话、短信、手机APP、微信公众号等方式通知用户。用户可手动或设置规则通过远程控制功能控制设备状态，实现平台对设备的远程控制。
                    </div>
                    <div className='mtb20'>
                        <Image width={'100%'}  height={'auto'} src={pic2} preview={false} />
                    </div>
                    <div className='mtb20'>
                        <div className='b mb10'>智能燃气管理系统的优势</div>
                        <div>
                        丰富的系统功能：提供各种 API 接口与现有的系统进行数据对接，提供各类的短信、语音、微信通知，个性化定制通知模板，操作画面简洁，界面优化，自动化程度高，功能、菜单、业务、权限动态配置，可以个性化定义角色，支持多种主流数据库，提供完善的报表统计功能，方便各级管理人员进行信息查询统计。
                        </div>
                        <div>
                        优越的性能：高效的通讯服务器，可以大规模的接入物联探测设备，动态更新升级设备固件信息，保持最新的工作状态，系统自动备份恢复，解决数据丢失问题。
                        </div>
                        <div>
                        灵活的扩充性：可以动态的新增设备接入协议，系统采用模块化设计，具有较强的可扩充性，支持通用的物联设备，比如 NB-IOT，Lora 等。
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}