import React,{Component} from 'react'
import {Image} from 'antd'

import pic1 from '../../../static/solution/solution3/pic1.png'
import pic2 from '../../../static/solution/solution3/pic2.png'

export default class Index extends Component{

    state = {  }

    render(){
        return(
            <div className='easy-in'>
                <div className='f18 b mb30 ac'>智能社区安防解决方案</div>
                <div className='mb30'>
                    <div className='b mb10'>方案背景</div>
                    <div>
                    众所周知，城市人口基数大、流动性高，且居住位置分散、管控难度极高。由此衍生的各类治安、刑事案件以及其他社会问题逐年上升趋势，给人民群众安居乐业、城市综合治理和公安"打、防、管、控"带来了巨大的考验和挑战。因此，亟需构建智能化、立体化的社区安全防控体系来提高社区安全治理水平，从而提高城市的综合管理能力。
                    </div>
                </div>
                <div className='mb30'>
                    <div className='b mb10'>方案概述</div>
                    <div>
                    “智慧社区智能安防系统”按照“统一规划、统一标准、统一平台、统一管理”的设计思路，通过社区内的视频监控、微卡口、人脸门禁和各类物联感知设备，实现社区数据、事件的全面感知，并充分运用大数据、人工智能、物联网等新技术，建设以大数据智能应用为核心的“智能安防社区系统”，形成了公安、综治、街道、物业多方联合的立体化社区防控体系，有效提升了特殊人群、重点关注、涉案、涉X等人员的管理能力，不断提高公安、综治等政府机关的预测预警和研判能力、精确打击能力和动态管理能力，提升社区防控智能化水平，提升居民居住幸福指数。
                    </div>
                    <div className='mtb20 ac'>
                        <Image width={'auto'}  height={'auto'} src={pic1} preview={false} />
                    </div>
                    <div>
                    "社区智能安防系统"提供集成的整体解决方案，包括可视对讲、视频监控、门禁、停车场、巡逻、梯子控制、访客、消费和智能家居系统。各系统的深度集成不仅实现了传统住宅小区弱电安全建设中的所有相关功能，还实现了各系统之间的信息共享和协调。同时，智能应用大大提高了业主的体验，使物业的运营和维护管理更加方便。
                    </div>
                    <div className='mt20 ac'>
                        <Image width={'auto'}  height={'auto'} src={pic2} preview={false} />
                    </div>
                </div>
                <div className='mb30'>
                    <div className='b mb10'>方案优势</div>
                    <div>
                        <span className='b'>对犯罪分子精准打击：</span>
                        通过人脸数据、门禁通行数据和案件数据进行关联分析，对犯罪嫌疑人进行轨迹刻画、精准布控，快速定位、有效打击。</div>
                    <div>
                        <span className='b'>预防各类案件发生：</span>
                        通过建立各类人员专题库，实现对人员属性的精细化管理，对进入社区或单元的可疑人员进行预警防控。</div>
                    <div>
                        <span className='b'>社区网格化管理：</span>
                        强化“数据采集，建立精细化社区网格，各类人员分布统计，提升社区网格化管理水平。</div>
                    <div>
                        <span className='b'>重点人员管控：</span>
                        建立重点人员库，对重点人员的卡/证信息、人脸信息采集，分析重点人员行为，对各类异常行为进行预警防控。</div>
                </div>

            </div>
        )
    }
}