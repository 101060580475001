import React,{Component} from 'react'
import {Image} from 'antd'

import pic1 from '../../../static/product/device3/pic1.png'
import pic2 from '../../../static/product/device3/pic2.png'
import device1 from '../../../static/product/device3/device1.png'
import device2 from '../../../static/product/device3/device2.png'
import device3 from '../../../static/product/device3/device3.png'
import device4 from '../../../static/product/device3/device4.png'
import device5 from '../../../static/product/device3/device5.png'
import device6 from '../../../static/product/device3/device6.png'
import device7 from '../../../static/product/device3/device7.png'
import device8 from '../../../static/product/device3/device8.png'

export default class Index extends Component{

    state = {}

    render(){
        return(
            <div className='easy-in'>
                <div className='f18 b mb30 ac'>WiFi 系统智能网关</div>
                <div className='mb30'>
                    <span className='b'>WiFi</span>
                    （Wireless Fidelity），WiFi技术通过无线电波来连网，嵌入式智能终端、无线WiFi技术，以及Internet的广泛应用使家居安防控制变得更加自动化、智能化和人性化，从而提供Wi-Fi智能家居安防解决方案。
                    <div className='mt20 ac'>
                        <Image preview={false} width={'80%'} height={'auto'} src={pic1} />
                    </div>
                </div>
                <div className='mb30'>
                    <div className='b mb10'>智能家居安防WiFi解决方案</div>
                    <div>
                    采用智慧WiFi网关+无线AP+智慧云平台形式进行组合，实现上网行为管理、流量控制、各AP设备集中管控、智慧WiFi认证及推广等功能。可普遍适合目前各中、小型所需场所。
                    </div>
                    <div className='mtb20'>
                        <div className='flex flex-center fc mb10' >
                            <div className='flex1 ac'>
                                <div className='relative dx'>
                                    <img src={device1} className='auto-img' alt="" />
                                    <div className='hover-content'>
                                        <div>
                                            <span className='mr20'>工作电压</span>DC 3V
                                        </div>
                                        <div>
                                            <span className='mr20'>发射频率</span>433.92MHz
                                        </div>
                                        <div>
                                            <span className='mr20'>通讯距离</span>{'>= 30M(空旷距离)'}
                                        </div>
                                    </div>
                                </div>
                                <div className='mtb10 of'>KD-601LA 系统遥控器</div>
                            </div>
                            <div className='flex1 ac'>
                                <div className='relative dx'>
                                    <img src={device2} className='auto-img' alt="" />
                                    <div className='hover-content'>
                                        <div>
                                            <span className='mr20'>工作电压</span>DC 3V
                                        </div>
                                        <div>
                                            <span className='mr20'>发射频率</span>433.92MHz
                                        </div>
                                        <div>
                                            <span className='mr20'>通讯距离</span>{'>= 30M(空旷距离)'}
                                        </div>
                                    </div>
                                </div>   
                                <div className='mtb10 of'>KD-602LA SOS一键求助</div>
                            </div>
                            <div className='flex1 ac'>
                                <div className='relative dx'>
                                    <img src={device3} className='auto-img' alt="" />
                                    <div className='hover-content'>
                                        <div>
                                            <span className='mr20'>工作电压</span>DC 3V
                                        </div>
                                        <div>
                                            <span className='mr20'>发射频率</span>433.92MHz
                                        </div>
                                        <div>
                                            <span className='mr20'>通讯距离</span>{'>= 30M(空旷距离)'}
                                        </div>
                                    </div>
                                </div>
                                <div className='mtb10 of'>KD-703LA 门窗报警器</div>
                            </div>
                            <div className='flex1 ac'>
                                <div className='relative dx'>
                                    <img src={device4} className='auto-img' alt="" />
                                    <div className='hover-content'>
                                        <div>
                                            <span className='mr20'>工作电压</span>DC 3V
                                        </div>
                                        <div>
                                            <span className='mr20'>发射频率</span>433.92MHz
                                        </div>
                                        <div>
                                            <span className='mr20'>通讯距离</span>{'>= 30M(空旷距离)'}
                                        </div>
                                    </div>
                                </div>
                                <div className='mtb10 of'>KD-701LA 溢水报警器</div>
                            </div>
                        </div>
                        <div className='flex flex-center fc' >
                            <div className='flex1 ac'>
                                <div className='relative dx'>
                                    <img src={device5} className='auto-img' alt="" />
                                    <div className='hover-content'>
                                        <div>
                                            <span className='mr20'>工作电压</span>DC 12V
                                        </div>
                                        <div>
                                            <span className='mr20'>发射频率</span>433.92MHz
                                        </div>
                                        <div>
                                            <span className='mr20'>通讯距离</span>{'最大30M(空旷距离)'}
                                        </div>
                                    </div>
                                </div>
                                <div className='mtb10 of'>KD-805A 系统网关</div>
                            </div>
                            <div className='flex1 ac'>
                                <div className='relative dx'>
                                    <img src={device6} className='auto-img' alt="" />
                                    <div className='hover-content'>
                                        <div>
                                            <span className='mr20'>工作电压</span>DC 3V
                                        </div>
                                        <div>
                                            <span className='mr20'>低压检测</span>2.2V
                                        </div>
                                        <div>
                                            <span className='mr20'>发射频率</span>433.92MHz
                                        </div>
                                        <div>
                                            <span className='mr20'>报警响度</span>{'>= 85dB/3M'}
                                        </div>
                                    </div>
                                </div>    
                                <div className='mtb10 of'>KD-122LA 烟雾报警器</div>
                            </div>
                            <div className='flex1 ac'>
                                <div className='relative dx'>
                                    <img src={device7} className='auto-img' alt="" />
                                    <div className='hover-content'>
                                        <div>
                                            <span className='mr20'>探测气体</span>烷类气体
                                        </div>
                                        <div>
                                            <span className='mr20'>工作电压</span>AC 200V
                                        </div>
                                        <div>
                                            <span className='mr20'>发射频率</span>433.92MHz
                                        </div>
                                        <div>
                                            <span className='mr20'>额定功率</span>5W
                                        </div>
                                        <div>
                                            <span className='mr20'>报警响度</span>{'>= 70dB(1M距离)'}
                                        </div>
                                        <div>
                                            <span className='mr20'>整机寿命</span>5年
                                        </div>
                                    </div>
                                </div>    
                                <div className='mtb10 of'>KD-212LA 燃气报警器</div>
                            </div>
                            <div className='flex1 ac'>
                                <div className='relative dx'>
                                    <img src={device8} className='auto-img' alt="" />
                                    <div className='hover-content'>
                                        <div>
                                            <span className='mr20'>工作电压</span>DC 3V(AAA*2)
                                        </div>
                                        <div>
                                            <span className='mr20'>发射频率</span>433.92MHz
                                        </div>
                                        <div>
                                            <span className='mr20'>通讯距离</span>{'<= 60M(空旷距离)'}
                                        </div>
                                        <div>
                                            <span className='mr20'>扇区角度</span>360° (吸顶安装)
                                        </div>
                                    </div>
                                </div>   
                                <div className='mtb10 of'>KD-702LA 红外探测仪</div>
                            </div>
                        </div>

                        <div className='ac'>
                            <Image width={'80%'} preview={false}  height={'auto'} src={pic2} />
                        </div>
                    </div>
                </div>
                <div className='mb30'>
                    <div className='b mb10'>WiFi智能网关在家居智能安防中的优势</div>
                    <div>
                        <span className='b'>覆盖广：</span>
                        无线WiFi技术突破覆盖范围广，WiFi半径可达100米完好匹配家居安防。 </div>
                    <div>
                        <span className='b'>传输快：</span>
                        传输速度非常快，符合业主用户需求。 </div>
                    <div>
                        <span className='b'>智能报警联动：</span>
                        通过安装门磁、窗磁，防止非法入侵，主人和小区警卫可通过安装在住户室内的报警控制器在小区管理中心得到信号从而快速接警处理。</div>
                    <div>
                        <span className='b'>紧急求助功能：</span>
                        安装在室内的报警控制器具有紧急呼叫功能，小区管理中心可对住户的紧急求助信号做出回应和救助。</div>
                </div>
            </div>
        )
    }
}