import React, { Component } from 'react'
import { Image, Divider } from 'antd';
import LeftMenu from './leftMenu'

import pic1 from '../../static/about/one.png'
import bannerPic from '../../static/home/banner4.png'

export default class Home extends Component {
    state = {}
    render() {
        return (
            <div className='bg-f7'>
                <div className='relative'>
                    <img src={bannerPic} style={{'width':'100%','height':'300px'}} alt="" />
                    <div className='absolute-center ac text-white no-select' style={{'letterSpacing':'3px'}}>
                        <div className='b f42 mb15'>关于我们</div>
                        <div className='f20' style={{'maxWidth':'600px'}}>
                        专注互联网信息化服务 加强交流合作 共谋协同发展
                        </div>
                    </div>
                </div>
                <div className='main-box'>
                    <div className='f16'>首页 / 关于我们 / 公司介绍</div>
                    <Divider />
                    <div className='flex'>
                        <LeftMenu></LeftMenu>
                        <div className='flex1 ml50'>
                            <div className='ac b f16'>公司介绍</div>
                            <div className='mtb20 ac'>
                                <Image preview={false} width={'100%'} height={'auto'} src={pic1} />
                            </div>
                            <div>
                                <span className='b'>江苏家之安安防科技有限公司</span>
                                是上市公司铠盾电子(股票代码:871171)全资子公司，铠盾电子是一家专业研发、制造、营销电子安防产品的大型企业，成立于2019年，宁波市高新企业，现有员工500余人，四万平米标准厂房，拥有安防工程技术中心，全尺寸火灾实验室，以及与TUV国际共建实验室。主要产品：各类烟雾火灾报警器，气体传感器报警器，电气火灾报警器等，主要销往美国、德国、英国、比利时、丹麦等多个国家，市场份额在同行业中占据领先地位。
                            </div>
                            <div className='mtb20'>专注于城市级物联网应用解决方案，以创新为动力，以应用促研发，专业从事消防物联网系列产品及软件平台的研发、生产、销售及服务，通过自主研发核心物联网技术，&nbsp;运用大数据、云计算、移动互联网等技术，搭建真正安全、智慧的消防体系，为政企单位及行业客户提供端对端的智慧消防一站式解决方案。
                            </div>
                            <div className='mtb20'>
                                公司经营范围包括：安防产品、电子电器产品的研发、制造、销售；软件的定制研发、销售；报警器控制模块的开发、制造、销售等。
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}