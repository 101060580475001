import React,{Component} from 'react'
import { Divider  } from 'antd';
import WithRouter from '../../components/withRouter';

import Project0 from './Project0'
import Project1 from './Project1'
import Project2 from './Project2'
import Project3 from './Project3'
import Project4 from './Project4'
import Project5 from './Project5'

import bannerPic from '../../static/home/banner5.png'

class Index extends Component {
    state = {
        titleIndex:0,
        titleArr:[
            {id:0,title:'LORA智慧城市安防解决方案',page:<Project0 />},
            {id:1,title:'NB-IOT智慧城市安防解决方案',page:<Project1 />},
            {id:2,title:'智能家居安防解决方案',page:<Project2 />},
            {id:3,title:'智能社区安防解决方案',page:<Project3 />},
            {id:4,title:'智慧井盖解决方案',page:<Project4 />},
            {id:5,title:'智慧消防解决方案',page:<Project5 />},
        ],
    }
    componentDidMount(){
        let location = this.props.router.location;
        if(location.state){
            const { index } = location.state;
            this.chooseTitle(index-1||0) ;
        }
        window.scrollTo(0, 0);
    }

    chooseTitle = (id)=>{
        const {titleIndex} = this.state;
        if(titleIndex===id){
            return false
        }
        this.setState({titleIndex:id})
    }

    render(){
        const {titleArr,titleIndex} = this.state;
        return (
            <div>
                <div className='relative'>
                    <img src={bannerPic} style={{'width':'100%','height':'300px'}} alt="" />
                    <div className='absolute-center ac text-white no-select'style={{'letterSpacing':'3px'}}>
                        <div className='b f42 mb15'>解决方案</div>
                        <div className='f20' style={{'maxWidth':'600px'}}>
                        推动智慧生活管理 助力企业安全发展
                        </div>
                    </div>
                </div>
                <div className='bg-f7'>
                    <div className='main-box'>
                        <div className='f16'>首页 / 解决方案 / {titleArr[titleIndex].title||''}</div>
                        <Divider />
                        <div className='flex'>
                            <div className='left-title-box'>
                                {
                                    titleArr.map(item=>{
                                        return (
                                            <div key={item.id} onClick={()=>this.chooseTitle(item.id)}
                                            className={`title-item ${item.id===titleIndex?'title-item-active':''}`}>{item.title}</div>
                                        )
                                    })
                                }
                            </div>
                            <div className='flex1 ml50'>
                                {
                                    titleArr[titleIndex].page
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default WithRouter(Index)