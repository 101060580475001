import './App.css'
import {
  HashRouter,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom'
import { useEffect,useState} from 'react';
import { BackTop } from 'antd'


import Header from './pages/headerMenu';
import Footer from './pages/footer';

import Home from './pages/home'
import Product from './pages/product'
import Solution from './pages/solution'
import Concat from './pages/aboutUs/concat'
import Convention from './pages/aboutUs/convention'
import Honor from './pages/aboutUs/honor'
import Introduce from './pages/aboutUs/introduce'
import Case from './pages/case'
import H5 from './pages/H5'
import H5Pad from './pages/h5Pad'

function App() {
  const [showLayout,changeShowLayout] = useState(true)

  useEffect(()=>{
    if(window.location.hash === '#/H5'){
      changeShowLayout(false)
    }
  },[])

  return (
    <HashRouter>
      {
        showLayout?<div style={{'background':'#187aff'}}>
        <div className='header'>
          <Header></Header>
        </div>
      </div>:''
      }
      
      <div>
        <Routes>
         <Route path="/home" element={<Home/>} />
         <Route path="/product" element={<Product/>} />
         <Route path="/solution" element={<Solution/>} />
         <Route path="/case" element={<Case/>} />

         <Route path="/concat" element={<Concat/>} />
         <Route path="/convention" element={<Convention/>} />
         <Route path="/honor" element={<Honor/>} />
         <Route path="/introduce" element={<Introduce/>} />
         <Route path="/h5" element={<H5/>} />
         <Route path="/h5Pad" element={<H5Pad/>} />
         

         <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
      </div>
      {
        showLayout?<div style={{'background':'#187aff'}}>
        <div className="footer">
        <Footer></Footer>
        </div>
      </div>:''
      }
      <BackTop />
    </HashRouter>
  );
}

export default App;
