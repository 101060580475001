import React,{Component} from 'react'
import {Image} from 'antd'

import pic1 from '../../../static/solution/solution5/pic1.png'
import pic2 from '../../../static/solution/solution5/pic2.png'

export default class Index extends Component{

    state = { }

    render(){
        return(
            <div className='easy-in'>
                <div className='f18 b mb30 ac'>智慧消防解决方案</div>
                <div className='mb30'>
                    <div className='b mb10'>方案背景</div>
                    <div>
                    近年来，随着城市现代化建设的发展，消防安全风险不断上升，消防安全问题越来越突出，国务院、公安部及省市各级领导高度重视。运用大数据、 物联网等技术构建 “智慧消防” 系统，有效整合社会各方力量，构建城市公共安全、火灾防控体系，成为解决现代城市消防管理难题的首要途径。
                    </div>
                    <div className='mt20 ac'>
                        <Image width={'auto'}  height={'auto'} src={pic1} preview={false} />
                    </div>
                </div>
                <div className='mb30'>
                    <div className='b mb10'>方案概述</div>
                    <div>
                    利于云计算、大数据、物联网、人工智能技术，对消防设备、器材、人员等状态进行智能化监管，通过信息处理，数据挖掘和趋势分析，为消防监督管理和救援提供信息支撑，提高消防日常管理水平，提供实时救灾数据支持。对提升大厦消防智能化扩展与应用。及时发现消除隐患、扩展管理地理边界、有效提升与监管消防维保质量。业主、物管、维保多方联动，实时监管消防设备状态，当险情发生为救援提供实时的数据支持，最大程度减少人员财产损失。
                    </div>
                    <div>智能消防平台功能包括：火灾报警监控、设备与故障监控、消防维保管理等。</div>
                    <div className='mt20'>
                    "智慧消防云平台"采用大数据、物联网等技术，汇聚多方数据，构建消防大数据汇聚与支撑平台，研发贯穿隐患排查、防火监督、灭火救援、后勤管理、大数据创新应用的消防应用系统。实现各项消防业务工作流程化管理和协同运作，落实消防安全责任制，提高各消防责任方的消防科技水平，促进消防工作社会化的贯彻与实施，全面提升消防安全水平。
                    </div>
                    <div className='mt20 ac'>
                        <Image width={'auto'}  height={'auto'} src={pic2} preview={false} />
                    </div>
                </div>
                <div className='mb30'>
                    <div className='b mb10'>方案优势</div>
                    <div>
                        <span className='b'>智能防控：</span>
                         发现异常自动报警 提升信息传递的效率智显消防云平台，利用物联网技术，结合大数据云平台，一旦检测到险情与异常，系统自动在第一时间通过终端设备通知用户及时处理。</div>
                    <div>
                        <span className='b'>智慧管理：</span>
                        标准化日常管理 保障消防设施的完好消防设施的管理依赖于人工，常见的形式就是由相关人员对设备进行检 查，然后登记相关情况。</div>
                    <div>
                        <span className='b'>智慧救灾：</span>
                        根据实时动态数据 更高效精准的救灾24小时有效监控与统一管理，让设备&gt;系统&gt;管理员&gt;安防主管&gt;业主&gt;救援资源&gt;消防部门，每个环节智能联动。</div>
                    <div>
                        <span className='b'>智慧指挥：</span>
                        现场可视化动态图像 实现调度智能化智慧消防链接，满足可视化、动态化指挥需求，实现各类资源的实时智能化调度，帮助以最快的速度扑救，最大化的保障人员财产安全。</div>
                </div>

            </div>
        )
    }
}