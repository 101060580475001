import React,{Component} from 'react'
import {Image} from 'antd'

import pic1 from '../../../static/product/device4/pic1.png'
import pic2 from '../../../static/product/device4/pic2.png'
import pic3 from '../../../static/product/device4/pic3.png'

export default class Index extends Component{
    state = {}
    render(){
        return(
            <div className='easy-in'>
                <div className='f18 b mb30 ac'>燃气智能切断阀</div>
                <div className='mb30'>
                    <span className='b mr10'>燃气智能切断阀</span>
                    <span>用于燃气输配系统中，监控燃气压力，当系统压力高于设定值时，安全阀可紧急切断电源，保证系统安全，
                        无须外加动力，利用管网自身压力信号即可完成自动切断动作。</span>
                </div>
                <div className='mb30'>
                    <span className='b mr10'>瓶装液化石油气</span>
                    <span>
                        主要成分是丙烷、丁烷。当空气中液化石油气浓度达到1.5%~9.5%时，
                        遇到明火和火花极易发生爆炸事故。本产品针对现状，提供结构简单、合理、安全可靠，泄漏检测的可燃气体智能切断阀。
                    </span>
                    <div className='mt20 ac'>
                        <Image preview={false} width={'90%'} height={'auto'} src={pic1} />
                    </div>
                </div>
                <div className='mb30'>
                    <span className='b mr10'>产品证书</span>
                    <div className='mt20 ac'>
                        <Image preview={false} width={'90%'} height={'auto'} src={pic2} />
                    </div>
                </div>
                <div className='mb30'>
                    <span className='b mr10'>燃气智能切断阀解决方案</span>
                    <span>
                    利用物联网和云计算技术，为燃气智能管理设备打造配套云平台，不仅能够对接智能设备采集燃气使用时的各种指标，
                    还能通过手机、电脑等设备进行远程监测和控制，实现根据设置自动调压、异常报警等诸多功能。
                    </span>
                    <div className='mt20 ac'>
                        <Image preview={false} width={'90%'} height={'auto'} src={pic3} />
                    </div>
                </div>
                <div className='mb30'>
                    <div className='b mr10 mb10'>燃气智能切断阀解决方案</div>
                    <div>
                        <span className='b'>更方便：</span>
                        人性化设计、内置电池、易拆解、易安装，无需电源线和插座等设备。</div>
                    <div>
                        <span className='b'>更安全：</span>
                        所需设备少，减少燃气通路连接点，减少管气破漏气可能，增加了设备的可靠性。</div>
                    <div>
                         <span className='b'>结构先进：</span>
                        结构简单合理、智能无线远程控制切断阀，从上游进气口直接关闭燃气。</div>
                    <div>
                        <span className='b'>节约成本：</span>
                        只需燃气警报器和减压阀两个设备，市场上同类传统产品需求5个以上设备，更符合家庭用户应用场景。</div>
                </div>
            </div>
        )
    }
}