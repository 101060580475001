import React,{Component} from "react";

import h5_pic from '../../static/base/h5.png'
export default class H5 extends Component{
    state={
        isShowPic:false,
    }

    componentDidMount(){
        // 一加手机不建议用默认浏览器打开
        this.test()
    }

    test = async()=>{
        var browser = {
            versions: function () {
                var u = navigator.userAgent
                return { //移动终端浏览器版本信息
                    ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
                    android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
                    iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
                    iPad: u.indexOf('iPad') > -1, //是否iPad
                };
            }(),
        }
        if (browser.versions.iPhone || browser.versions.iPad || browser.versions.ios) {
            this.toApple()
        }else {//安卓
            var ua = window.navigator.userAgent.toLowerCase();
            if(ua.match(/MicroMessenger/i) === "micromessenger"){//微信内置浏览器
                this.setState({isShowPic:true})
            }else {//普通浏览器
                this.setState({isShowPic:false})
                let res = await this.ajax();
                alert(res)
                window.location.href = res;
            }
        }
    }
    toApple=()=>{ 
        window.location.href = 'https://apps.apple.com/cn/app/id1610091417'
    }
    ajax = async()=>{
        const url = 'https://weixin.jzasafe.com/miniapp/app/getVersion'
        let postData = {
            appType:'android',
            appName:'家之安'
        }

        const options = {
            method:'post',
            headers: {
                'Content-Type': 'application/json;charset=utf-8;'
            },
            body:JSON.stringify(postData)
        }
        try {
            let response = await fetch(url,options);
            let data = await response.json();
            if(data.resp_code === 1){
                let apkUrl = data.datas.url;
                return apkUrl
            }
        } catch(e) {
            console.log("Oops, error", e);
        }
    }

    render(){
        const {isShowPic} = this.state;
        return (
            <div className="bg-white">
                {
                    isShowPic?<img src={h5_pic} style={{'width':'100vw','height':'100vh'}}
                    alt="" />:''
                }
                
            </div>
        )
    }
}