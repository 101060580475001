import React,{Component} from 'react'
import {Image} from 'antd'

import pic1 from '../../../static/solution/solution2/pic1.png'
import pic2 from '../../../static/solution/solution2/pic2.png'

export default class Index extends Component{

    state = { }

    render(){
        return(
            <div className='easy-in'>
                <div className='f18 b mb30 ac'>智能家居安防解决方案</div>
                <div className='mb30'>
                    <div className='b mb10'>方案背景</div>
                    <div>
                    随着智能家居的快速发展，越来越多的智能产品进入家居生活，并实现应用场景。那么，如何让家居生活当中的一个个场景与技术相融合？我们来看看全屋智能家居系统解决方案吧！相信有了这些解决方案，能给予大家一个便捷、舒适、安全的智能家居系统。
                    </div>
                    <div className='mt20 ac'>
                        <Image width={'auto'}  height={'auto'} src={pic1} preview={false} />
                    </div>
                </div>
                <div className='mb30'>
                    <div className='b mb10'>方案概述</div>
                    <div>
                    借助新一代的物联网、云计算、决策分析优化等信息技术，以一种更智慧的方式运行，围绕家居安防中的重点和薄弱环节，利用先进可靠适用的信息技术和创新的产品理念，在火灾预警、天然气泄漏、煤气中毒、溢水报警、门窗异动、老人监护求助等方面提高家居安防管理的精细化、智能化水平。
                    </div>
                    <div>
                    我们将地理信息系统与异动终端作为家居安防应急响应的展现基础，通过物联网和无线联网安防产品的交互系统，建立家居智慧安防系统综合管理云平台，将所有智能家居安防产品和物联网设备，统一对接到云平台，并实时通过手机APP、微信公众号、短信、电话的方式把家居安防信息实时推送给相关用户，实现统一管理，预警分析，智能监管。 
                    </div>
                    <div className='mtb20 ac'>
                        <Image width={'auto'}  height={'auto'} src={pic2} preview={false} />
                    </div>
                    <div>智慧家居安防平台运用NB-IoT、LoRa物联网技术，由烟雾警报器等设备、基站设备、网络服务平台、云服务器、客户端等组成。系统通过采集的信息同步发送到物联网云平台，由云平台推送信息通知用户人员实时处理情况。</div>
                </div>
                <div className='mb30'>
                    <div className='b mb10'>方案优势</div>
                    <div>
                        <span className='b'>智能安全：</span>
                        智能门锁、红外人体探测器、门窗报警器可以保证家居室内的防盗、防劫。探测到有人非法闯入侦测区域时，实时向手机推送报警信息，并有视频随时回放功能，保证房屋安全。</div>
                    <div>
                        <span className='b'>实时监控：</span>
                        出门在外就怕家中出点什么事，漏水、漏气、起火这些都是我们所担心的，如何能够把这些情况预防控制呢？通过智能监测设备（烟雾警报器、燃气报警器、水浸报警器、摄像头等），对险情实施监控，已有安全隐患警情的时候能够第一时间通知用户，并且能够联动其他设备来控制险情。</div>
                    <div>
                        <span className='b'>智能联动：</span>
                        全面集成语音电话远程控制、定时控制、场景控制、无线转发等控制功能，也能远程通知用户实时家居情况。</div>
                </div>
            </div>
        )
    }
}