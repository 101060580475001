import React,{Component} from 'react'
import {Image} from 'antd'

import pic1 from '../../../static/solution/solution4/pic1.png'
import pic2 from '../../../static/solution/solution4/pic2.png'

export default class Index extends Component{

    state = {  }

    render(){
        return(
            <div className='easy-in'>
                <div className='f18 b mb30 ac'>智慧井盖解决方案</div>
                <div className='mb30'>
                    <div className='b mb10'>方案背景</div>
                    <div>
                    随着城市化进程的进一步加快，市政公用设施建设发展迅速。市政、电力、通信等部门有大量市政设备、资产需要管理。其中井盖成为不可忽视的一项。目前，全国范围内已有井盖50亿个以上，全国每年新增、更换井盖1500万个以上。大量井盖由于缺乏有效的监管手段，井盖的移位、丢失、损坏，管道的堵塞，不仅影响了相关设备的正常工作，造成直接或间接的经济损失，同时也会对道路上的车辆、行人造成极大的损害，对社会安定、安全造成负面影响。
                    </div>
                    <div className='mt20 ac'>
                        <Image width={'auto'}  height={'auto'} src={pic1} preview={false} />
                    </div>
                </div>
                <div className='mb30'>
                    <div className='b mb10'>方案概述</div>
                    <div>
                    根据城市井盖分布的特点，充分结合实际应用场景，因地适宜，通过NB-IOT射频采集网、CWSN无线云传感网以及GPRS无线云传感网等多种组网的方式，在无需布线的情况下，可快速实现“城市井盖”智能实时云监测物联网系统的部署，极大缩短实时监测系统的施工时间，并降低了系统的实施成本。系统具有很强的扩展性，可随时增加网络监测点。
                    </div>
                    <div>
                    通过物联网技术，将城市里最难管理的地井信息加入智慧城市管理范畴，实现对窨井井盖实时监测，实现城市井盖安全防御监测，实现智慧城市的精细化管理，管理人员通过管理系统获取报警信息、锁定险情、快速定位并及时处置地井异常状况，减少事故发生。对井盖状态监控、实时报警、自动巡检、及时处置等功能、保障安全运行，进一步提高市政管理的信息化、智能化水平，为智慧城市奠定行业应用基础。 
                    </div>
                    <div className='mt20'>
                    "智能井盖系统"利用传感器，将所有管辖区域内各类井盖建立唯一的井盖识别码，统一监控管理起来。利用井盖上的传感器采集到的信息，及时的上传到云平台上，并通过井盖智能管理系统将信息推送到管理人员的电脑和手机APP上，这样就可以实现对井盖的状态实时监控和异常情况及时处理。
                    </div>
                    <div className='mt20 ac'>
                        <Image width={'auto'}  height={'auto'} src={pic2} preview={false} />
                    </div>
                </div>
                <div className='mb30'>
                    <div className='b mb10'>方案优势</div>
                    <div>
                        <span className='b'>精确数据信息：</span>
                         平台实时上传统计并修改各区域智慧井盖具体信息，信息全面、精准，实现井盖的及时主动盘点，方便管理部门的信息采集及情况处理。</div>
                    <div>
                        <span className='b'>实时监控：</span>
                        实现系统可实时监测智慧井盖的各种状态信息，通过结合辖区地图，可实时查看智慧井盖在所辖区内的位置和基本属性信息，并对各辖区内所属的智慧井盖防盗进行统一指挥调度处置和工程维护。</div>
                    <div>
                        <span className='b'>报警管理：</span>
                        平台预先设定报警规则，对智能井盖的异常情况进行紧急报警。提倡低功耗设计，当智慧井盖状态正常时，监控终端处于休眠状态。当智慧井盖出现异常时，监控终端立即唤醒并发出报警信号通知相关负责部门采取措施。</div>
                    <div>
                        <span className='b'>自动巡检：</span>
                        设备可自动巡检并上报心跳，遇故障自动报警;所有巡检结果将形成每天巡检报告图表化报表分析展示数据，值班人员通过巡检结果可清晰了解设备运行情况，实现自动化、智能化管理。</div>
                </div>

            </div>
        )
    }
}