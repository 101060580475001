import React,{Component} from 'react'
import { Image  } from 'antd';
import CardItem  from './CardItem';
import WithRouter from '../../components/withRouter';
import DxBanner from '../../components/DxBanner'

import companyPic from '../../static/home/companyPic.png'
import rightIcon from '../../static/home/rightIcon.png'

import eventBus from '../../libs/event.js'

class Home extends Component {
    state = {
        caseArr:[
            {id:1,title:'智慧消防物联网综合管理应用平台',pic:require('../../static/home/case1.png'),
            content:'采用大数据、物联网等技术，汇聚多方数据，构建消防大数据汇聚与支撑平台，研发贯穿隐患排查、防火监督、灭火救援、后勤管理、大数据创新应用的消防应用系统。实现各项消防业务工作流程化管理和协同运作，落实消防安全责任制，提高各消防责任方的消防科技水平，促进消防工作社会化的贯彻与实施，全面提升消防安全水平。'},
            {id:2,title:'江苏柒熙燃气安全智能管理系统',pic:require('../../static/home/case2.png'),
            content:'借助新的科技设备帮助各类角色优化流程、快速获取信息、把握重点，采用电话、短信、微信通知的方式进行交互，将燃气与日常的通讯相结合，解决消息送达迟滞的问题。'},
            {id:3,title:'湖南电气火灾管理应用平台',pic:require('../../static/home/case3.png'),
            content:'基于移动互联网的电气安全云平台，通过先进的智能传感器，全天候将电气线路安全数据实时传送至云平台，实现电气安全隐患管理，并实时监控、远程控制、web端管理、APP应用、大数据分析及社区和企业级电气安全服务。通过物联网技术对电气引发火灾的主要因素（导线温度、电流、电压和漏电流）进行不间断的数据跟踪与统计分析，实时发现电气线路和用电设备存在的安全隐患（如：线缆温度异常、短路、过载、过压、欠压及漏电等），第一时间发出警报信息并准确报出故障点，自动通知相关人员及时排除电气火灾隐患。'},
        ],
        planArr:[
            {id:1,title:'LORA智慧城市安防解决方案',pic:require('../../static/home/plan1.png'),
            content:'LORA智慧城市安防平台基于LoRa物联网技术，由烟雾警报器等设备、基站设备、网络服务平台、云服务器、客户端等组成。系统通过采集的信息同步发送到物联网云平台，由云平台推送通知相关部门人员实时处理情况。'},
            {id:2,title:'NB-IOT智慧城市安防解决方案',pic:require('../../static/home/plan2.png'),
            content:'NB-IOT慧城市安防平台基于NB-IoT物联网技术，由烟雾警报器等设备、基站设备、网络服务平台、云服务器、客户端等组成。系统通过采集的信息同步发送到物联网云平台，由云平台推送通知相关部门人员实时处理情况。'},
            {id:3,title:'智能家居安防解决方案',pic:require('../../static/home/plan3.png'),
            content:'借助新一代的物联网、云计算、决策分析优化等信息技术，以一种更智慧的方式运行，围绕家居安防中的重点和薄弱环节，利用先进可靠适用的信息技术和创新的产品理念，在火灾预警、天然气泄漏、煤气中毒、溢水报警、门窗异动、老人监护求助等方面提高家居安防管理的精细化、智能化水平。'},
        ],
    }

    toMore = (path)=>{
        this.props.router.navigate(path);
        eventBus.emit("changeMenuActive",'#'+path)    
    }
    toSolutionPage = (id)=>{
        this.props.router.navigate('/solution',{
            state:{
                index:id
            }
        });
        eventBus.emit("changeMenuActive",'#/solution')    
    }

    render(){
        const {caseArr,planArr} = this.state;
        return (
            <div>
                <DxBanner></DxBanner>
                <div className='bg-f7'>
                    <div className='main-box'>
                        <div className='plr15 mb40'>
                            <div className='ac f20'>公司简介</div>
                            <div className='ac mb20'>Company Profile</div>
                            <div className='flex flex-center'>
                                <div  className='dx-img'>
                                    <Image  src={companyPic} preview={false} ></Image>
                                </div>
                                <div className='flex1 ml30 easy-in'>
                                    <div className='f16 b mb15'>江苏家之安安防科技有限公司</div>
                                    <div className='mb20'>成立于2019年成立；专注于城市级物联网应用解决方案，以创新为动力，以应用促研发，专业从事消防物联网系列产品及软件平台的研发、生产、销售及服务，通过自主研发核心物联网技术， 运用大数据、云计算、移动互联网等技术，搭建真正安全、智慧的消防体系，为政企单位及行业客户提供端对端的智慧消防一站式解决方案。</div>
                                    <div>公司经营范围包括：安防产品、电子电器产品的研发、制造、销售；软件的定制研发、销售；报警器控制模块的开发、制造、销售等。</div>
                                </div>
                            </div>
                        </div>

                        <div className='mb40'>
                            <div className='ac f20'>案例展示</div>
                            <div className='ac'>Case Presentation</div>
                            <div className='b ar text-blue mb10'>
                                    <span className='pointer vm' onClick={()=>this.toMore('/case')}>More</span>
                                    <span className='iconfont f16 vm mlr10'>&#xe65d;</span>
                            </div>
                            <div className='clear'>
                                {
                                    caseArr.map(item=>{
                                        return(
                                            <CardItem key={item.id} {...item}></CardItem>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        
                        <div>
                            <div className='ac f20'>解决方案</div>
                            <div className='ac'>Solution Case</div>
                            <div className='b ar text-blue mb10'>
                                <div>
                                    <span className='pointer vm' onClick={()=>this.toMore('/solution')}>More</span>
                                    <span className='iconfont f16 vm mlr10'>&#xe65d;</span>
                                </div>
                            </div>
                            <div className='clear'>
                                {
                                    planArr.map(item=>{
                                        return(
                                            <div className='w33 plr15 pointer' key={item.id} >
                                                <div className=' shadow-gray' onClick={()=>this.toSolutionPage(item.id)}>
                                                <Image width={'100%'} height={250} src={item.pic} preview={false}></Image>
                                                <div className='p10' >
                                                    <div className='b mb10 of'>{item.title}</div>
                                                    <div className='of of3'>{item.content}</div>
                                                    <div className='ar'>
                                                        <Image src={rightIcon} preview={false} width={20} height={16}></Image>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default WithRouter(Home)