import React,{Component} from 'react'
import {Image} from 'antd'

import two from '../../../static/product/device2/two.png'
import device4 from '../../../static/product/device2/device4.png'
import device5 from '../../../static/product/device2/device5.png'
import device6 from '../../../static/product/device2/device6.png'
import device7 from '../../../static/product/device2/device7.png'

export default class Index extends Component{

    state = {}

    render(){
        return(
            <div className='easy-in'>
                <div className='f18 b mb30 ac'>LoRa-网关，烟感、燃气、温感</div>
                <div className='mb30'>
                    <span className='b'>LoRa</span>
                    （Long Range Radio），LoRa技术独立于蜂窝网络。是一种低功耗远程无线通信技术，它的覆盖范围广，算是一种广域网WAN，LoRa采用扩频技术。远程(LoRa)无线技术可以帮助城市克服网络覆盖方面的难题，并使他们能够在智能城市中广泛部署物联网解决方案，从而提供传统Wi-Fi解决方案无法提供的覆盖范围。
                </div>
                <div className='mb30'>
                    <div className='b mb10'>LoRa智慧消防整体解决方案</div>
                    <div>
                    通过LPWAN无线技术完成所有子系统联网，实时监测，远程控制等功能，通过云计算平台实现百万级节点接入和管理，通过大数据技术实现消防数据实时分析，实时报警，隐患排查，远程通知等功能。
                    </div>
                    <div className='mtb20'>
                        <div className='flex flex-center fc' >
                            <div className='flex1 ac mr10'>
                                <div className='relative dx'>
                                    <img src={device4} className='auto-img' alt="" />
                                    <div className='hover-content'>
                                        <div>
                                            <span className='mr20'>工作电压</span>DC 3V
                                        </div>
                                        <div>
                                            <span className='mr20'>通讯距离</span>{'> 500M'}
                                        </div>
                                        <div>
                                            <span className='mr20'>通讯方式</span>LORA
                                        </div>
                                    </div>
                                </div>
                                <div className='mtb10 of'>KD-805D系统网关</div>
                            </div>
                            <div className='flex1 ac mr10'>
                                <div className='relative dx'>
                                    <img src={device5} className='auto-img' alt="" />
                                    <div className='hover-content'>
                                        <div>
                                            <span className='mr20'>工作电压</span>DC 3V
                                        </div>
                                        <div>
                                            <span className='mr20'>低压检测</span>2.2V
                                        </div>
                                        <div>
                                            <span className='mr20'>报警响度</span>{'>= 85dB/3M'}
                                        </div>
                                        <div>
                                            <span className='mr20'>通讯方式</span>LORA
                                        </div>
                                    </div>
                                </div>    
                                <div className='mtb10 of'>KD-122LD_Lora智能光电感烟火灾探测报警器</div>
                            </div>
                            <div className='flex1 ac mr10'>
                                <div className='relative dx'>
                                    <img src={device6} className='auto-img' alt="" />
                                    <div className='hover-content'>
                                        <div>
                                            <span className='mr20'>探测气体</span>烷类气体
                                        </div>
                                        <div>
                                            <span className='mr20'>工作电压</span>AC 200V
                                        </div>
                                        <div>
                                            <span className='mr20'>额定功率</span>5W
                                        </div>
                                        <div>
                                            <span className='mr20'>报警响度</span>{'> 70dB(1M距离)'}
                                        </div>
                                        <div>
                                            <span className='mr20'>整机寿命</span>5年
                                        </div>    
                                        <div>
                                            <span className='mr20'>通讯方式</span>LORA
                                        </div>
                                    </div>    
                                </div>   
                                <div className='mtb10 of'>KD-212LD可燃气体探测报警器 燃气报警器</div>
                            </div>
                            <div className='flex1 ac'>
                                <div className='relative dx'>
                                    <img src={device7} className='auto-img' alt="" />
                                    <div className='hover-content'>
                                        <div>
                                            <span className='mr20'>工作电压</span>DC 3V
                                        </div>
                                        <div>
                                            <span className='mr20'>低压检测</span>2.2V
                                        </div>
                                        <div>
                                            <span className='mr20'>报警响度</span>{'>= 85dB/3M'}
                                        </div>
                                        <div>
                                            <span className='mr20'>通讯方式</span>LORA
                                        </div>
                                    </div>
                                </div>    
                                <div className='mtb10 of'>KD-705LB感温火灾报警器</div>
                            </div>
                        </div>
                        <div className='ac'>
                        <Image width={'80%'} preview={false}  height={'auto'} src={two} />
                        </div>
                    </div>
                    <div>
                    智慧消防云平台是一个采用“感、传、知、用”等物联网技术手段，通过互联网，对消防设施、器材、人员等状态进行智能化感知、识别、定位与跟踪，实现实时信息传递，分析，为防火监督管理和灭火救援提供信息支撑，提高社会化消防监督与管理水平的智能化平台。能够对烟、水、电、气等引发的消防事故因素进行24小时监测控制。
                    </div>
                </div>
                <div className='mb30'>
                    <div className='b mb10'>LoRa在智慧消防中的特性</div>
                    <div>
                        <span className='b'>能耗低：</span>
                        大大的改善了接收的灵敏度，降低了功耗。</div>
                    <div>
                        <span className='b'>容量大：</span>
                        基于该技术的网关/集中器支持多信道多数据速率的并行处理，系统容量大。</div>
                    <div>
                        <span className='b'>定位准：</span>
                        基于终端和集中器/网关的系统可以支持测距和定位</div>
                    <div>
                        <span className='b'>易于组网：</span>
                        只需将网关安装在稿件组顶端，即可实现大范围网络覆盖，无需复杂布线、安装。</div>
                    <div>
                        <span className='b'>免授权：</span>
                        LoRa技术有着自己独有的服务器，不用依托通信运营商的网络。</div>
                </div>
            </div>
        )
    }
}