import React,{Component} from 'react'
import { Divider  } from 'antd';
import LeftMenu from './leftMenu'

import bannerPic from '../../static/home/banner4.png'

const AMap = window.AMap;

export default class Home extends Component{
    
    componentDidMount(){
        // 初始化地图
        let map = new AMap.Map('container', {
            zoom: 16,
            center: [120.361615, 31.507611],
        });
        let marker = new AMap.Marker({
            position: new AMap.LngLat(120.361615, 31.507611),
            title: '江苏家之安安防科技有限公司'
        });
        map.add(marker)
        window.dxMap = map;
    }
    componentWillUnmount(){
        // 释放地图
        window.dxMap&&window.dxMap.destroy()
        window.dxMap = null;
    }

    render(){
        return(
            <div className='bg-f7'>
                <div className='relative'>
                    <img src={bannerPic} style={{'width':'100%','height':'300px'}} alt="" />
                    <div className='absolute-center ac text-white no-select'style={{'letterSpacing':'3px'}}>
                        <div className='b f42 mb15'>关于我们</div>
                        <div className='f20' style={{'maxWidth':'600px'}}>
                        专注互联网信息化服务 加强交流合作 共谋协同发展
                        </div>
                    </div>
                </div>
                <div className='main-box'>
                    <div className='f16'>首页 / 关于我们 / 联系我们</div>
                    <Divider />
                    <div className='flex'>
                        <LeftMenu></LeftMenu>
                        <div className='flex1 ml50'>
                            <div className='ac b f16'>联系我们</div>
                            <div className='b mtb20'>江苏家之安安防科技有限公司</div>
                            <div>电话：0510-85331107</div>
                            <div>邮编：214115</div>
                            <div>邮箱：xlinlong@jzasafe.com</div>
                            <div className='mb20'>地址：江苏省无锡市新吴区菱湖大道228号天安智慧城A3-1001</div>

                            <div id="container" style={{ width:"100%", height:"300px" }}></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}