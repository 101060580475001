
import React,{useState}from 'react'
import { useNavigate } from "react-router-dom";

export default function Index() {
    const push = useNavigate();

    // const pageIndex = ['/introduce','/honor','/concat'].findIndex(it=>it===window.location.pathname)
    const pageIndex = ['#/introduce','#/honor','#/concat','#/convention'].findIndex(it=>it===window.location.hash)

    const [titleIndex, setIitleIndex] = useState(pageIndex||0);//析构赋值
    const [titleArr] = useState(
        [
            { id: 0, title: '公司介绍', path: '/introduce' },
            { id: 1, title: '企业荣誉', path: '/honor' },
            { id: 2, title: '联系我们', path: '/concat' },
            { id: 3, title: '自律公约', path: '/convention' }
        ]
    );//析构赋值
    
   const chooseTitle = (item)=>{
        setIitleIndex(item.id)
        push(`${item.path}`,{replace: true});
    }

    return (
        <>
            <div className='left-title-box'>
                {
                    titleArr.map(item => {
                        return (
                            <div key={item.id} onClick={()=>{chooseTitle(item)}}
                                className={`title-item text-black ${item.id === titleIndex ? 'title-item-active' : ''}`}>
                                <div>{item.title}</div>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}