import React, { Component } from 'react'
import QRCode  from 'qrcode.react';
import { Image } from 'antd';

import eqCode1 from '../../static/base/eqCode1.png'
import eqCode2 from '../../static/base/eqCode2.png'
import appLogo from '../../static/home/appLogo.png'
import gonanIcon from './icon.png'

export default class Index extends Component {

    state={
        ERcodeUrl:window.location.origin + '/#/H5',
        year:'',
    }

    componentDidMount(){
        this.setState({year:new Date().getFullYear()})
    }


    render() {
        const {ERcodeUrl,year} = this.state;
        return (
            <div>
                <div className='flex pt20 f16'>
                    <div className='flex1'>
                        <div className='b mb20'>联系我们</div>
                        <div className='mb10'>客服热线：0510-85331107</div>
                        <div className='mb10'>邮箱：xlinlong@jzasafe.com</div>
                        <div className='mb10'>地址：无锡市新吴区菱湖大道228号天安智慧城A3-1001</div>
                    </div>
                    <div className='flex1'>
                        <div className='b f16 mb20'>生态合作</div>
                        <div className='mb10'>宁波铠盾电子工业股份有限公司</div>
                        <div className='mb10'>江苏柒熙城市智能科技有限公司</div>
                    </div>
                    <div className=''>
                        <div className='b f16 mb20'>关于我们</div>
                        <div className=''>
                            <div className='ac inline-block'>
                                <Image src={eqCode1} preview={false} width={120} height={120}></Image>
                                <div className='f12'>关注公众号：家之安安防</div>
                            </div>
                            <div className='ac inline-block mlr20'>
                                <Image src={eqCode2} preview={false} width={120} height={120}></Image>
                                <div className='f12'>关注小程序：家之安安防</div>
                            </div>
                            <div className='ac inline-block'>
                                <QRCode value={ERcodeUrl} size={120}
                                style={{ padding: '5px',backgroundColor:'#fff' }}
                                 imageSettings={{
                                    src: appLogo,
                                    height: 20,
                                    width: 20,
                                    excavate: true,
                                 }}
                                 ></QRCode>
                                <div className='f12'>应用安装</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='f14 ac vm'>© 2021-{year} 江苏家之安安防科技有限公司 版权所有 
                <a className='text-white vm ml10' href="http://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">苏ICP备19053287号-1</a>
                <img className="vm ml10 mr5" src={gonanIcon} alt='' style={{width:'16px',height:'16px'}}/>
                <a className='text-white vm' href="https://beian.mps.gov.cn/#/query/webSearch?code=32021402002384" target="_blank" rel="noopener noreferrer">苏公网安备32021402002384号</a>
                </div>
            </div>
        )
    }
}